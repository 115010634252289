import { Button, Form, Input, Row, Select, Switch, Tooltip } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { ChangeEvent, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MessageContext } from "../../context/messageContext";
import { ICreateChatTemplate } from "../../types";
import parseErrors from "../../utils/parseErrors";
import { addChatTemplate } from "../../utils/api";

const { Option } = Select;

const CreateChatTemplates = () => {
    const navigate = useNavigate();
    const messageContext = useContext(MessageContext);
    const [templateData, setTemplateData] = useState<ICreateChatTemplate>({
        title : "",
        prompt : "",
        category : ""
    });
    const [form] = Form.useForm();

    const onChange = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
        setTemplateData((data) => ({
        ...data,
        [name]: event.target.value,
        }));
    };

    const selectChange = (value: string) => {
        setTemplateData((data) => ({
        ...data,
        category: value,
        }));
    }

    const goBack = () => {
        navigate(-1);
    };

    const createAdmin = async () => {
        try {
            await addChatTemplate(templateData);
            goBack();
        } catch (error: any) {
            console.log(error);
            const errors = parseErrors(error.response?.data?.error);

            for (let i = 0; i < errors.length; i += 1) {
                messageContext.instance?.open({
                type: "error",
                content: errors[i],
                });
            }
        }
    };


    return (
        <div className="admin">
            <Row className="title">
                <Tooltip title="Back">
                <Button onClick={goBack} icon={<LeftOutlined />} />
                </Tooltip>

                <h1>Add Prompt Template</h1>
            </Row>

            <Row>
                <Form
                initialValues={templateData}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                form={form}
                name="control-hooks"
                onFinish={createAdmin}
                >
                <Form.Item name="title" label="Title">
                    <Input value={templateData.title} onChange={onChange("title")} />
                </Form.Item>

                <Form.Item name="prompt" label="Prompt">
                    <Input value={templateData.prompt} onChange={onChange("prompt")} />
                </Form.Item>

                <Form.Item name="cat" label="Category" rules={[{ required: false }]}>
                    <Select
                        placeholder="Select categoty if this prompt is for any particular hypelist type"
                        onChange={selectChange}
                        allowClear={false}
                        defaultValue={templateData.category}
                    >
                        <Option value="">AI Chatbot</Option>
                        <Option value="places">Places</Option>
                        <Option value="fitness">Fitness</Option>
                        <Option value="movies">Movies</Option>
                        <Option value="books">Books</Option>
                        <Option value="products">Products</Option>
                        <Option value="music">Music</Option>
                        <Option value="links">Links</Option>
                        <Option value="others">Others</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    className="btn-container"
                    wrapperCol={{ offset: 8, span: 16 }}
                >
                    <Button type="primary" htmlType="submit">
                    Add
                    </Button>
                </Form.Item>
                </Form>
            </Row>
        </div>
    );
};

export default CreateChatTemplates
